@import 'css/_constants';
body {
    margin: 0;
    padding: 0;
    font-family: $font-stack;
    font-display: swap;
}

input,
textarea,
select,
button {
    font-family: $font-stack;
    font-display: swap;
}

@-ms-viewport {
    width: device-width;
}
