@import '../../css/_constants';

.modal-overlay {
    position: fixed;
    top: 0;
    left: 0;
    right: 0;
    bottom: 0;
    background-color: $transparent-grey;
    z-index: 1000;
}

.modal-content {
    position: absolute;
    border: 1px solid $main-text-grey;
    background-color: $semi-transparent-black;
    color: $dark-grey;
    svg {
        color: $dark-grey;
    }
    overflow: auto;
    -webkit-overflow-scrolling: touch;
    border-radius: $button-border-radius;
    outline: none;
    padding: 0;
    max-width: 100%;
    z-index: 1001;
}

#modal-close-button {
    svg {
        font-size: 2.5em;
        color: black;
    }
    z-index: 1001;
    line-height: 0;
    padding: 0.2em 0.1em 0.2em 0.1em;
}

@media (max-width: 700px) {
    .modal-content {
        top: 0;
        left: 0;
        right: 0;
        bottom: 0;
        border-radius: 0;
        max-width: 100%;
    }
    #modal-close-button {
        position: fixed;
        top: 2px;
        right: 2px;
    }
}

@media (min-width: 700px) {
    .modal-content {
        top: 0px;
        left: 0px;
        right: 0px;
        bottom: 0px;
        max-height: 100%;
        max-width: 100%;
    }
    #modal-close-button {
        position: fixed;
        top: 2px;
        right: 2px;
    }
}

.confirmation-wrapper {
    min-width: 250px;
    max-width: 100%;
    font-weight: bold;
    font-size: 1.5em;

    background-color: $dark-grey;
    color: white;

    display: block;
    padding: 20px;
    position: fixed;
    left: 50%;
    top: 50%;
    -webkit-transform: translate(-50%, -50%);
    transform: translate(-50%, -50%);
    button {
        padding: 0.5em;
        margin: 1em;
        font-size: 0.75em;
        color: white;
    }
}

.confirmation-message {
    display: block;
    overflow: scroll;
    max-width: 100%;
}

.confirmation-button-outer-wrapper {
    display: block;
    clear: both;
    width: 100%;
    height: 100%;
}

.confirmation-button-wrapper {
    padding: 10px;

    min-width: 250px;

    position: relative;
    left: 50%;
    top: 50%;
    -webkit-transform: translate(-50%, 0%);
    transform: translate(-50%, 0%);

    display: grid;
    grid-template-columns: 1fr 1fr;
    grid-gap: 10px;
}

.confirmation-action-button {
    background-color: $hover-red;
    grid-column: 1/2;
    justify-self: center;
}

.confirmation-cancel-button {
    background-color: $dark-grey;
    grid-column: 2/3;
    justify-self: center;
}
