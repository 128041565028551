@import url('https://fonts.googleapis.com/css?family=Montserrat');
// colors
$main-bg-black: #101010;
$transparent-black: rgba(0, 0, 0, 0);
$semi-transparent-black: rgba(0, 0, 0, 0.025);

$white: #fff;
$off-white: #f1f1f1;

$button-color: rgba(200, 200, 200, 0.8);
$button-background: rgba(64, 64, 64, 0.2);
$button-background-hover: rgba(255, 255, 255, 0.6);
$button-color-hover: rgba(255, 255, 255, 0.8);
$button-stroke: rgba(100, 100, 100, 0.5);
$button-border-radius: 4px;
$button-border-radius-sharper: 6px;

$button-on-color: rgb(19, 129, 19);
$button-on-border: 2px solid white;
$button-on-background: rgb(88, 255, 88);
$button-off-color: rgba(255, 255, 255, 0.7);
$button-off-border: 2px solid white;
$button-off-background: rgba(115, 194, 123, 0.242);

$light-grey: #ddd;
$medium-grey: #aaa;
$main-text-grey: #999;
$dark-grey: #555;
$transparent-dark-grey: rgba(100, 100, 100, 0.5);

$transparent-grey: rgba(0, 0, 0, 0.2);
$more-transparent-grey: rgba(255, 255, 255, 0.2);

$green: #43c843;
$darker-green: #2caa39;
$dark-green: #1b6423;

// usually the less transparent one is for hovers
$transparent-green: rgba(67, 200, 67, 0.8);
$more-transparent-green: rgba(67, 200, 67, 0.7);

$red: rgba(120, 59, 43, 1);
$hover-red: rgba(120, 59, 43, 0.88);
$invisible: rgba(100, 100, 100, 0);

$yellow: #ffff42;

// fonts
$font-stack: Montserrat, 'Open Sans', Helvetica, Arial, sans-serif;

// z-index constants
$overlay-text-z-index: 40;

// mixins
%hide {
    display: none;
}

//animations
@keyframes spin {
    0% {
        transform: rotate(0deg);
    }
    100% {
        transform: rotate(360deg);
    }
}

// containers
.container-container {
    width: 100%;
    height: 100%;
}

.image-container {
    overflow: hidden;
    position: absolute;
}

// bits
.image {
    width: 100%;
    height: 100%;
}
